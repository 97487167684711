.image-feature{
	position: relative;
	padding: 45px 20px;

	h1,h2,h3,h4,h5{
		font-size: 2rem;
		padding-bottom: 20px;
	}

	p{
		padding-bottom: 1.5em;
	}

	a{
		.button;
	}

	header{
		padding: 30px 0;
	}

	ul{
		padding-bottom: 35px;
	}

	li{
		position: relative;
		overflow: hidden;
		padding-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.icon{
			float: left;
			width: 50px;
			height: 50px;
			padding: 10px;
			background: #E0E0E0;
			margin-right: 15px;
		}

		.icon + div{
			width: ~"calc(100% - 75px)";
			*{
				padding: 0 !important;
			}
		}
	}

.desktop({
	padding-top:0;
	
	.background-image{
		position: relative;
		.inner;
		max-width: 850px;
		padding-bottom: 60px;
		margin-bottom: 15px;

		img{
			display: block;
			width: 600px;
			position: relative;
		}

		&:before{
			position: absolute;
			top:0;
			left:0;
			width: 33vw;
			margin-left: -16.5vw;
			bottom:0;
			content:"";
			background: @primary-bg;
			
		}
	}

	header{
		position: relative;
		max-width: 750px;
		margin: auto;

		h1,h2,h3,h4,h5{
			position: relative;
			font-size: 3rem;

			&:before{
				position: absolute;
				top: 20px;
				left: -120px;
				width: 100px;
				height: 4px;
				content:"";
				background: @secondary-bg;
			}
		}
	}

	article{
		position: relative;
		max-width: 750px;
		margin: auto;
	}

	.icon-list{
		position: relative;
		overflow: hidden;

		li{
			float: left;
			width: 50%;
			padding-bottom: 15px;
		}
	}
});

	
}