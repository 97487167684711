.footer{
	position: relative;
	padding: 15px;
	background: @primary-bg;
	color: #fff;

	a{
		color: #fff;

		&:hover{
			text-decoration: underline;
		}
	}

	h6{
		font-size: 2rem;
		padding: 10px 0;
		margin:0;
	}

	.column{
		padding-bottom: 30px;
	}

	.socials {
		ul {
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
		}
		a {
			color: #fff;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 35px;
			line-height: 35px;
			width: 35px;
			border-radius: 100%;
			background: @primary-bg;
			text-align: center;
			padding: 1px;
			svg {
				display: block;
				height: 25px;
				width: 25px;
				fill: currentColor;
			}
		&:hover, &:focus {
			background: #fff;
    	color: @text;
		}
		}

		li a[href=""] {
			display: none;
		}

	}

	li{
		list-style: none;
	}

	.inner + .inner{
		padding-top: 30px;
		border-top: 1px solid #fff;
	}

	p + p{
		// padding-top:1.5em;
	}

.desktop({
	.inner{
		display: flex;
		flex-wrap: wrap;
	}
	.logo-container{
		width: 200px;
		margin-right: 30px;
		padding-top: 10px;
		font-size: 2.5rem;
		text-transform: uppercase;
		font-weight: bold;
	}
	.column{
		width: calc(33.333% - 100px);
	}

	.column + .column{
		padding-left: 25px;
	}

	.inner + .inner{
		justify-content: space-between;
		p{
			width: 25%;
			padding-top: 0;

			&:nth-of-type(2){
				text-align: center;
			}
		}


	}
});
}