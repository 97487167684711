#simple-header {
	position: relative;
	padding: 0 20px;
	background: #333;
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	color: #fff;
	background-size: cover;
	height: 50vh;
	padding-top: 5vh;

	&:before{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		content:"";
		background: #333;
		opacity: .4;
	}

	h1{
		padding-bottom: 8vh;
		font-size: 6rem;
	}

	p{
		padding-bottom: 1em;
	}

	.editable{
		a{
			.button;
			margin: auto;
		}
	}

	a.special{
		display: block;
		margin: auto;
		max-width: 380px;

		img{
			display: block;
			width: 100%;
		}
	}
}