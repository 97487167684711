#header{
	position: relative;
	padding: 15px;
	z-index: 1000;

	.inner{
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	.nav-container{
		display: none;
	}

	#mobile-grip{
		height: 48px;
		width: 52px;
		margin: 0;
		span{
			display: none;
		}
	}

	.logo-container{
		position: relative;
		height: 48px;
		line-height: 48px;
		max-width: 70%;
		text-transform: uppercase;
		font-size: 2rem;
		font-weight: bold;

		a{
			color: #fff;
		}
	}

.desktop({
	#mobile-grip{
		display: none;
	}

	.nav-container{
		display: block;
		position: absolute;
		top: 0;
		right:0;
		height: 48px;
		border-bottom: 1px solid #fff;

		li{
			float: left;
		}
	
		a{
			border-right: 1px solid #fff;
			display: block;
			height: 24px;
			line-height: 24px;
			padding: 12px 22px;
			font-size: 1.3rem;
			color: #fff;

			&:hover{
				background: #fff;
				color: @primary-bg;
			}
		}
	}

	button span{
		display: none;
	}

	#grip{
		box-sizing: border-box;
		height: 48px;
		width: 48px;
		padding:0;
		background: none;
		border:0;
		z-index: 50000;
	}
});

	
}