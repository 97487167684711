.block.video{
	position: relative;
	padding: 45px 20px;
	font-size: 1.8rem;

	h1,h2,h3,h4,h5{
		font-size: 3rem;
		padding-bottom: 20px;
	}

	header{
		position: relative;
		max-width: 750px;
		margin: auto;
		padding-bottom: 50px;

		h1,h2,h3,h4,h5{
			position: relative;
			font-size: 3rem;

			&:before{
				position: absolute;
				top: 20px;
				left: -120px;
				width: 100px;
				height: 4px;
				content:"";
				background: @secondary-bg;
			}
		}
	}

	&.mid{
		.vidholder{
			margin: auto;
			max-width: 560px;
		}
	}

	&.full{
		.vidholder{
			margin: auto;
			max-width: 100%;
		}
	}
.tablet({

});

.desktop({
	
});
}