.block.floorplan{
	padding: 45px 20px;

	.inner{
		display: flex;
		flex-direction: column;
	}

	aside{
		position: relative;
		order: 2;
	}

	a {
		color: #4C4E56;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	.widget{
		background: #F1F1F1;
		padding: 10px;
		border: 1px solid #DADADA;
		margin-bottom: 10px;

		ul{
			list-style: none;
			display: flex;
		}

		li{
			max-width: 50%;
		}

		li + li{
			padding-left: 10px;
		}

		label{
			font-weight: bold;
		}

		h3{
		}
	}

	article{
		order: 1;
		position: relative;
		background: #F1F1F1;
		padding: 10px;
		border: 1px solid #DADADA;
		margin-bottom: 10px;
		list-style: none;

		a{
			display: block;
			color: #333;
		}

		.thumbnail{
			display: block;
			padding-bottom: 30px;

			img{
				width: 100%;
				display: block;
			}
		}

		.info{
			padding-bottom: 30px;
		}

		h3{
			font-size: 2rem;
			font-weight: bold;
			padding-top: 10px;
			margin-top: 10px;
			border-top: 1px solid #DADADA;
		}

		dl{
			overflow: hidden;
			padding: 0 0 10px;
			margin: 0;
		}

		dt{
			text-transform: uppercase;
			float: left;
			width: 30%;
			clear: both;
		}

		dd{
			float: left;
		}

		p{
			clear: both;
			font-style: italic;
			font-size: 1.6rem;
		}

		label{
			font-weight: normal;
		}
	}

	table{
		width: 100%;
		text-align: left;
		border-bottom: 1px solid #ddd;

		tr{
			th{
				font-weight: bold;
				width: 50%;
				margin:0;
				padding-left: 8px;
				border: 1px solid #ddd;
				border-right: 0;
				border-bottom:0;
			}

			td:first-of-type{
				padding: 5px;
				margin:0;
				border: 1px solid #ddd;
				border-left: 0;
				border-bottom:0;
			}
		}

		tr:nth-child(odd){
			margin:0;
			background: #F1F1F1;
		}
	}

.tablet({
	.inner{
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	aside{
		position: static;
		width: 250px;
		margin-left: 20px;

		form{
			padding-top: 10px;
		}

		label{
			font-size: 1.6rem !important;
		}

		input, textarea{
			font-size: 1.7rem !important;
		}
	}

	article{
		width: ~"calc(100% - 270px)";
		padding:0;
		background: none;
		border:0;

		.active-image{
			padding: 10px;
			margin-bottom: 25px;
			border: 1px solid #ddd;
		}

		dl{
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-column-gap: 0px;
			grid-row-gap: 0px;
		}

		dt{
			border-bottom: 1px solid #ddd;
			clear: none;
			float: none;
			text-align: left;
			width: 100%;
		}

		dd{
			padding-left:0;
			margin-left:0;
		}

		.g1 { grid-area: 1 / 1 / 2 / 2; }
		.g2 { grid-area: 2 / 1 / 3 / 2; }
		.g3 { grid-area: 1 / 2 / 2 / 3; }
		.g4 { grid-area: 2 / 2 / 3 / 3; }
		.g5 { grid-area: 1 / 3 / 2 / 4; }
		.g6 { grid-area: 2 / 3 / 3 / 4; }
		.g7 { grid-area: 1 / 4 / 2 / 5; }
		.g8 { grid-area: 2 / 4 / 3 / 5; }
	}

	.widget{
		margin-bottom: 15px;
		.droplist{
			display: block;
			li{
				max-width: 100%;
				display: flex;
			}

			label{
				width: 45%;
				margin-right: 5%;
			}

			select{
				width: 50%;
			}

			li + li{
				padding: 10px 0 0 0;
			}

		}
	}
});


.desktop({

});
}