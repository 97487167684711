html {
	font-size: 62.5%;
	// scroll-behavior: smooth;
}

body{
	font-size: 1.7rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: 1170px;
	width: 100%;
}

#cookie-box {
	z-index: 999999 !important;
}