.contact{
	position: relative;
	padding: 45px 20px;
	background: @secondary-bg;


	h1,h2,h3,h4,h5{
		font-size: 2rem;
	}

	.iwrap{
		padding-bottom: 20px;
	}

	label{
		font-weight: normal;
		padding-bottom: 4px;
	}

	aside{
		> div{
			color: #fff;
			background: @primary-bg;
			padding: 15px;
		}
		a {
			color: #fff;
			text-decoration: none;
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}

	p + h5{
		padding-top: 15px;
	}

.desktop({
	padding: 55px 0;

	h1,h2,h3,h4,h5{
		font-size: 3rem;
	}

	.inner{
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	header{
		width: 100%;
	}

	article{
		width: 70%;
	}

	aside{
		width: 28%;
		margin-left: 2%;
		box-sizing: border-box;
		padding-top: 27px;

		h2,h3,h4,h5{
			font-size: 2.5rem;
			padding-bottom: 15px;
		}
	}

	form{
		position: relative;
		overflow: hidden;
		max-width: 100% !important;
	}

	.fields{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.form-group {
		padding-bottom: 12px;
	}

	.iwrap, .form-group {
		width: 48%;

		&.quarter{
			width: 23%;
		}

		&.half{
			width: 48%;
		}

		&.third{
			width: 73%;
		}

		&.one-third{
			width: 31%;
		}

		&.two-thirds{
			width: 65%;
		}

		&.textarea, &.submit, &.full{
			width: 100%;
		}

		&.submit{
			padding-left: 0;
			margin-left: 0;
		}
	}

});


}