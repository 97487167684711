.floor-plans{
	position: relative;
	padding: 45px 20px;
	background: @secondary-bg;

	h1,h2{
		font-size: 3rem;
	}

	p{
		padding-bottom: 1.5em;
	}

	a {
		color: #4C4E56;
	}

	// a{
	// 	.button;
	// }

	aside{
		overflow: hidden;
		padding-bottom: 75px;
	}

	.box{
		float: left;
		width: 50%;
		padding-bottom: 10px;

		h3{
			font-size: 1.8rem;
		}

		&:nth-of-type(3){
			clear: both;
		}

		ul{
			display: flex;
			gap: 10px;
			flex-wrap: wrap;
    	flex-direction: row;
		}

		li{
			list-style: none;
			font-weight: bold;
			width: fit-content;
			label {
				margin-left: 5px;
			}
		}

		select{
			display: block;
			// width: 90%;
		}
	}

	.floorplan-item, .floorplan{
		position: relative;
		border: 1px solid #adadad;
		border-bottom:0;
		background: #eee;
		margin-bottom: 25px;

		figure{
			position: relative;
			padding: 15px;
		}

		h3{
			font-size: 2rem;
			padding: 10px 15px;
			border-top: 1px solid #adadad;
			border-bottom: 1px solid #adadad;
		}

		table{
			width: 100%;
			text-align: left;
			border-collapse: collapse;
		}

		th, td{
			padding: 5px 0 5px 15px;
			border-bottom: 1px solid #adadad;
		}
	}

.desktop({
	.inner{
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	aside{
		width: 240px;
		background: #eee;
		padding: 15px;
		box-sizing: border-box;

		h2,h3,h4,h5,h6{
			font-size: 2rem;
		}
	}

	.box{
		position: relative;
		width: 100%;
		float: none;
		padding-bottom: 40px;

	}

	article{
		width: ~"calc(100% - 260px)";
	}

	.floorplan-item, .floorplan{
		display: flex;

		figure{
			width: 25%;
		}

		.info{
			width: 75%;
		}

		h3{
			border-top: 0;
			padding-top: 30px;
			padding-bottom: 40px;
		}

		td{
			border-bottom: 0;
		}
	}

	tbody{
		width: 100%;
	}

	th, td{
		width: 25%;
	}
});
}