.hero{
	position: relative;
	overflow: hidden;
	height: 100vh;
	margin-top: -78px;

	&.player{
		height: ~"calc(100vh + 78px)";
	}

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.inner{
		height: 100%;
	}

	.background-image{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;

		&:after{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			content:"";
			background: @primary-bg;
			opacity: .7;
		}

		img{
			display: block;
			min-height: 100%;
			min-width: 100%;
			width: auto;
			max-width: 400%;
		}
	}

	.hero-content{
		padding: 0 15px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: left;
		color: #fff;

		> *{
			width: 100%;
		}
	}

	h1,h2,h3{
		font-size: 3rem;
		line-height: 1;
		padding-bottom: 15px;
	}

	p{
		padding-bottom: 1em;
	}

	a{
		.button;
		.inline;
		width: auto;
		min-height: auto;
		padding-right: 12px;
		padding-left: 12px;

		&:hover{
		}
	}

	.desktop({
		
		.hero-content{
			width: 40%;
			margin-left: 0;
			position: relative;
			box-sizing: border-box;
			padding: 145px 45px 0 10px;
			color: #fff;

			* {
				position: relative;
			}

			&:before{
				position: absolute;
				top:0;
				left:-9000px;
				right:-9000px;
				bottom:0;
				content: "";
				background: @primary-bg;
				opacity: .7;
			}
		}
	});
}