.scroll-gallery{
	position: relative;
	padding: 45px 20px;
	background: @primary-bg;
	color: #fff;

	& + .scroll-gallery{
		background: #fff;
		color: @text;

		.gallery-nav{
			li{
				a{
					background: #E0E0E0;
				}
			}
		}
	}

	h1,h2{
		font-size: 3rem;
	}

	p{
		padding-bottom: 1.5em;
	}

	header{
		a{
			.button;
		}
	}

	.gallery-items{
		li{
			&:first-of-type{
				display: block;
			}
		}
	}

	.gallery-nav{
		display: block;
		position: relative;

		li{
			list-style: none;
			display: inline-block;
			color: @button-bg;
			padding-right: 12px;
			a{
				position: relative;
				width: 60px;
				height: 60px;
				display: block;
				background: #E0E0E0;
				padding-top: 1px;
			}

			svg{
				color: @button-bg;
				margin: auto;
				height: 40px;
				width: 40px;
				display: block;
				margin-top: 10px;
			}

			span{
				display: none;
			}
		}
	}

	.gallery-items {
		height: 300px;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		li {
			height: 100%;
			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}
	}

	.tablet({
		.gallery-items {
			height: 400px;
		}
	});

.desktop({
	padding: 90px 0;
	.inner{
		position: relative;
		margin: auto;
		max-width: 1080px;
	}

	header{
		max-width: 750px;
		margin: auto;
		text-align: center;
	}

	.gallery-items {
		height: 600px;
	}
});
}

// styles only when JS is on
.run-js .scroll-gallery{
	.gallery-items{
		li{
			list-style: none;
			display: none;

			&:first-of-type{
				display: block;
			}
		}
	}
}

// styles only for the builder
#rs-builder .scroll-gallery{
	ul {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		height: fit-content;
		justify-content: flex-start;
		margin-bottom: 20px;
		li {
			height: 200px;
			width: 200px;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
}